import { memo, forwardRef } from 'react';

import Box from '@mui/material/Box';

import { SxProps } from '@mui/material/styles';
import { ScrollbarProps } from '#/types/elements';
import {
  StyledRootScrollbar,
  StyledScrollbar,
} from '#/components/shared/ScrollbarStyles';

interface ExtendedScrollbarProps extends ScrollbarProps {
  styledRootScrollbarSx?: SxProps;
}

const Scrollbar = forwardRef<HTMLDivElement, ExtendedScrollbarProps>(
  ({ children, sx, styledRootScrollbarSx, ...other }, ref) => {
    const userAgent =
      typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

    const mobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    if (mobile) {
      return (
        <Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
          {children}
        </Box>
      );
    }

    return (
      <StyledRootScrollbar
        sx={{
          ...styledRootScrollbarSx,
        }}
      >
        <StyledScrollbar
          scrollableNodeProps={{
            ref,
          }}
          clickOnTrack={false}
          sx={sx}
          {...other}
        >
          {children}
        </StyledScrollbar>
      </StyledRootScrollbar>
    );
  }
);

export default memo(Scrollbar);
